<template>
  <!DOCTYPE html>
  <html lang="ja">
    <head>
      <!-- Google tag (gtag.js) -->

      <meta charset="UTF-8" />
      <title>LABORAT 0.1</title>
      <meta name="description" content="LABORAT 0.1" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <link rel="stylesheet" href="https://use.typekit.net/clc5sjy.css" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap"
        rel="stylesheet" />
      <link rel="shortcut icon" href="images/favicon.ico" />
      <link rel="stylesheet" href="css/reset.css" />
      <link rel="stylesheet" href="css/style.css" />
      <meta property="og:url" content="https://laborat.jp/" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://laborat.jp/images/ogp.png" />
      <meta property="og:title" content="LABORAT 0.1" />
      <meta property="og:description" content="初めまして。" />
    </head>
    <body>
      <div class="bg">
        <div class="wrapper">
          <div class="scroll_area">
            <header>
              <div class="hd_wrap sa sa--down">
                <h1><img class="pc" src="images/fv_pc.svg" alt="" /></h1>
                <h1><img class="sp" src="images/fv_sp.svg" alt="" /></h1>
              </div>
            </header>
            <section class="content_wrap sa sa--down">
              <div class="sec_content">
                <h2 class="eng">Presentation</h2>
                <div class="y_border"></div>
                <div class="main_text">
                  <p>
                    初めまして。<br />
                    この度、我々”<span class="eng">LABORAT</span>
                    (ラボラット)”は新たな音楽制作・リスナー体験を提案する実験的なプロジェクトとして発足します。<br />
                    <span class="reline"></span>
                    詳細については追ってご報告してまいりますので、ご期待いただければ幸いです。<br />
                    <span class="reline"></span>
                    キーワードは、<br />
                    <span class="eng">Laboratory</span> = 実験室<br />
                    <span class="eng">Labor</span> = 陣痛<br />
                    <span class="eng">Rat</span> = スパイ、ネズミ。<br />
                    <span class="reline"></span>
                    現在、独自のシステムを開発検討中です。<br />
                    <span class="reline"></span>
                    つきましては、2023年5月19日(金)。<span class="eng">LABORAT 0.1</span>と題し、
                    <span class="eng">test type</span>としての編成でステージプレゼンテーションを行いますので、
                    こちらにて今後の展望等をお伝えする所存です。<br />
                    イベント詳細は下記をご確認ください。<br />
                    <span class="reline"></span>
                    最初の<span class="eng">RAT</span>はあなたです。
                  </p>
                </div>
              </div>
            </section>
            <section class="content_wrap sa sa--down">
              <div class="sec_content">
                <h2 class="eng">Event</h2>
                <div class="y_border"></div>
                <!-- <div class="main_text">
                  <p>
                    5月19日(金)札幌Sound lab mole<br />
                    DRAMAticEND主催Event<br />
                    「<span class="eng">DtE Film No.00</span>」
                    <span class="reline"></span>
                    adv 3,000 / day 3,500 (+1Drink¥600)<br />
                    open 16:30 / start 17:00<br />
                    <span class="reline"></span>
                    Act<br />
                    DRAMAticEND<br />
                    DISCORD SILENCE<br />
                    エラー。<br />
                    ナデシコドール<br />
                    LABORAT 0.1<br />
                    LivEarth<br />
                    他<br />
                    <span class="reline"></span>
                    転換DJ<br />
                    たちゅやブスれきゅん<br />
                    <span class="reline"></span>
                    チケット発売中<br />
                    <a href="https://t.livepocket.jp/e/dte519" target="_blank"> https://t.livepocket.jp/e/dte519</a>
                  </p>
                  <img class="event_img" src="images/event_img1.jpg" alt="" />
                </div> -->
                <div class="main_text">
                  <p>
                    2023.10.14(土)<span class="eng">札幌VyPass.</span><br />
                    <span class="eng">joppin:cal & LAY ABOUT WORLD 2man Live</span> <br />
                    共存するアイデンティティ <span class="eng">Vol.1_DAY1</span>
                    <span class="reline"></span>
                    adv 3,500 / day 4,000 (各D代別)<br />
                    open 17:00 / start 17:30<br />
                    ※20:30公演終了予定
                    <span class="reline"></span>
                    Act<br />
                    LAY ABOUT WORLD<br />
                    joppin:cal<br />
                    LABORAT(オープニングゲスト)<br />
                    <span class="reline"></span>
                    チケット発売:9/2(土) 11:00～<br />
                    PassMarket:<br />
                    <a href="https://t.co/slHxgvCHS2" target="_blank"> https://t.co/slHxgvCHS2</a>
                  </p>
                  <img class="event_img" src="images/2023/10/14.jpg" alt="" />
                </div>
              </div>
            </section>
            <section class="content_wrap sa sa--down">
              <div class="sec_content">
                <h2 class="eng">LABORAT 0.1</h2>
                <h2 class="eng">
                  <router-link to="/LabContent">Test Page</router-link>
                </h2>
                <div class="y_border"></div>
                <div class="member_icon">
                  <div class="icon_box">
                    <a href="https://twitter.com/Kento_LABORAT" target="_blank">
                      <div class="icon_img"></div>
                      <p class="icon_name eng">KENTO</p>
                    </a>
                  </div>
                  <div class="icon_box">
                    <a href="https://twitter.com/Takumi_LABORAT" target="_blank">
                      <div class="icon_img"></div>
                      <p class="icon_name eng">TAKUMI</p>
                    </a>
                  </div>
                  <div class="icon_box">
                    <a href="https://twitter.com/Kyohei_Shiba" target="_blank">
                      <div class="icon_img"></div>
                      <p class="icon_name eng">KYOHEISHIBA</p>
                    </a>
                  </div>
                  <div class="icon_box">
                    <a href="https://twitter.com/bass_Ysk" target="_blank">
                      <div class="icon_img"></div>
                      <p class="icon_name eng">YUSUKE</p>
                    </a>
                  </div>
                </div>
                <a class="twi_link" href="https://twitter.com/LABORAT_info" target="_blank">
                  <p class="eng">LABORAT Twitter</p>
                </a>
              </div>
            </section>
            <footer>
              <div class="ft_wrap">
                <small class="eng">©︎2023 LABORAT All Rights Reserved.</small>
              </div>
            </footer>
          </div>
        </div>
      </div>
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.4/css/all.css" />
      <div id="page_top"><a href="#"></a></div>
    </body>
  </html>
</template>

<script>
export default {
  data() {
    return {
      htmlContent: '', // HTMLファイルの内容を保持するためのデータプロパティ
    }
  },

  mounted() {
    // HTMLファイルの読み込み
    // fetch('indexPage.html')
    //   .then((response) => response.text())
    //   .then((html) => {
    //     this.htmlContent = html
    //   })
  },
}
</script>
