import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import './assets/styles.css'

loadFonts()
import WaveSurferVue from 'wavesurfer.js-vue'

createApp(App).use(WaveSurferVue).use(router).use(store).use(vuetify).mount('#app')
