<template>
  <MainLabInformation ref="vvv" :projectData="projectData" :activeTubeData="activeTubeData" />
  <AudioVisualizerWave ref="bbb" :activeTubeData="activeTubeData" class="pa-0" />
  <!-- {{ activeTubeData }} -->
  <v-divider color="#9F9F9F" class="border-opacity-50 divider-position" />
  <v-container class="pa-0 pt-4">
    <v-row justify="end" class="pr-4">
      <v-col class="pa-2 d-flex justify-end align-center aaa">
        <v-btn density="comfortable" size="small" variant="outlined" color="white">
          <v-icon>mdi-share-variant</v-icon>
          <span @click="sns">SNSへリーク</span>
        </v-btn>
      </v-col>
      <div class="box pr-6">
        <span class="pa-2 d-flex justify-start align-center like_counter aaa">{{ likeCounter }}</span>
        <v-icon class="chu" color="#fff400" size="large" icon="mdi-hexagon"></v-icon>
        <div class="icon-with-text" @click="chu()">
          <v-icon class="heart" size="large">mdi-heart-outline</v-icon>
          <span>Chuu!</span>
        </div>
      </div>
    </v-row>
    <!-- <TestTest></TestTest> -->
  </v-container>
</template>
<script>
import { defineComponent } from 'vue'
import MainLabInformation from './MainLabInformation.vue'
import AudioVisualizerWave from './AudioVisualizerWave.vue'
// import TestTest from './TestTest.vue'

// interface NewValueInterface {
//   tubeId: number
//   tubeNo: string
//   updateAt: string
//   musicFile: string
// }
export default defineComponent({
  components: {
    AudioVisualizerWave,
    MainLabInformation,
    // TestTest,
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    activeTube: {
      type: Object,
      required: true,
    },
  },
  watch: {
    activeTube(newValue) {
      console.log('newValue')
      this.activeTubeData = newValue
      this.$refs.vvv.reset()
    },
  },
  computed: {
    computedMessage() {
      return this.activeTubeData
    },
  },
  data() {
    return {
      likeCounter: 0,
      activeTubeData: null,
    }
  },
  methods: {
    play() {
      this.$refs.bbb.play()
    },
    pause() {
      this.$refs.bbb.pause()
    },
    sns() {
      alert('まだ実装出来てないよ！ここはあなたのSNSのアカウントで投稿できるボタンになります！ごめんね(＾ω＾)')
    },
    chu() {
      alert('まだ実装出来てないよ！ここはいいと思ったら思った分だけ押せるボタンになります！ごめんね(＾ω＾)')
    },
  },
})
</script>
<style scoped>
.like_counter {
  background-color: aliceblue;
  border-radius: 5px;
  width: 90px;
  height: 10px;
}
.aaa {
  height: 22px;
}
.chu {
  transform: scale(3);
  right: -70px;
}
.icon-with-text {
  position: relative;
  align-items: center;
}
.icon-with-text .v-icon {
  position: absolute;
  bottom: 35px;
  left: 70px;
}

.icon-with-text span {
  position: relative;
  z-index: 1;
  bottom: 15px;
  left: 63px;
}
.box {
  position: relative;
  height: 35px;
}
.divider-position {
  position: relative;
}
</style>
