import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_menu = _resolveComponent("header-menu")!
  const _component_main_lab = _resolveComponent("main-lab")!
  const _component_footer_components = _resolveComponent("footer-components")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createElementVNode("p", null, [
            _createVNode(_component_header_menu, { class: "header-menu" }),
            _createVNode(_component_main_lab, {
              class: "main-lab",
              projectData: _ctx.selectedProjectData
            }, null, 8, ["projectData"]),
            _createVNode(_component_footer_components, {
              class: _normalizeClass(_ctx.isMobileView ? 'footer-components-mobile' : 'footer-components')
            }, null, 8, ["class"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}