<template>
  <v-container class="pt-4 pr-0">
    <v-card class="mx-auto" variant="text">
      <v-card-text class="pa-0">
        <v-row>
          <!-- <div id="waveform"></div> -->
          <v-col cols="12" class="wavesurfer_container">
            <!-- 波形表示部分 -->
            <div class="wavesurfer">
              <wavesurfer :src="musicFileData" :options="options" ref="surf" @click="seek()"></wavesurfer>
            </div>
          </v-col>

          <!-- 再生/停止 ボタン -->
          <v-col cols="1" align-self="center" class="wavesurferBtn">
            <!-- 再生ボタン -->
            <!-- <v-img src="@/assets/l_play_btn.svg" v-if="!nowPlaying" @click="play"></v-img> -->
            <!-- <v-btn
              v-if="!nowPlaying"
              dark
              icon
              @click="play"
              variant="outlined"
              color="white"
              size="x-large"
            >
              <v-icon dark size="x-large">mdi-play</v-icon>
            </v-btn> -->
            <!-- 停止ボタン -->
            <!-- <v-img src="@/assets/stop_btn.svg" v-if="nowPlaying" @click="pause"></v-img> -->
            <!-- <v-btn
              v-if="nowPlaying"
              dark
              icon
              color="white"
              @click="pause"
              size="x-large"
              variant="outlined"
            >
              <v-icon dark size="x-large">mdi-pause</v-icon>
            </v-btn> -->
          </v-col>
          <!-- シークバー -->
          <!-- <v-col cols="12">
            <input
              type="range"
              :min="minSongDuration"
              :max="maxSongDuration"
              v-on:input="seek"
              v-model="currentTimeValue"
            />
          </v-col> -->
          <!-- 再生位置時間/再生時間 -->
        </v-row>
        <v-row class="time justify-space-between">
          <!-- <v-col class="d-flex justify-start" cols="6"> -->
          <span class="pl-4 time-data d-flex justify-centar align-center">{{ currentTimeData }}</span>
          <!-- </v-col> -->
          <!-- <v-col class="d-flex justify-end" cols="6"> -->
          <span class="pl-1 time-data d-flex justify-centar align-center">{{ durationTime }}</span>
          <!-- </v-col> -->
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    activeTubeData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        normalize: true,
        scrollParent: false,
        barWidth: 2,
        hideScrollbar: true,
        // minPxPerSec: 100,
        pixelRatio: 1,
        // partialRender: true,
        // 色関係
        waveColor: '#717171',
        progressColor: ['#CAA34F', '#FAF262'],
      },
      musicFile: require('@/assets/Be_Awake/1_Idea.wav'),
      currentTime: '00:00',
      durationTime: '00:00',
      nowPlaying: false,
      fileLastModified: '',
      minSongDuration: 0,
      maxSongDuration: 1,
      currentTimeValue: 0,
    }
  },
  watch: {
    activeTubeData(newValue) {
      console.log('newValue')
      this.musicFile = newValue.musicFile
      this.loadWaveform()
      this.getCurrentTime(true)
      this.getDuration()
      this.pause()
    },
  },
  mounted() {
    this.player.on('loading', () => {
      console.log('loading')
    })

    this.player.on('ready', () => {
      console.log('ready1')
      this.getDuration()
      this.$refs.surf.wavesurfer.enableDragSelection({
        color: 'rgba(110, 255, 0, 0.3)',
        drag: ({ startX, endX }) => {
          const duration = this.wavesurfer.getDuration()
          const startPosition = startX / this.wavesurfer.drawer.width
          const endPosition = endX / this.wavesurfer.drawer.width
          const startTime = startPosition * duration
          const endTime = endPosition * duration

          this.wavesurfer.seekAndCenter((startTime + endTime) / 2)
          this.wavesurfer.fireEvent('seek', (startTime + endTime) / 2)
        },
      })
      this.wavesurfer.load(this.musicFile)
    })
    this.player.on('audioprocess', () => {
      this.getCurrentTime()
    })
    this.player.on('seek', () => {
      console.log('シークしました')
      this.getCurrentTime()
    })
  },
  computed: {
    player() {
      console.log('player()')
      return this.$refs.surf.waveSurfer
    },
    musicFileData() {
      return this.musicFile
    },
    currentTimeData() {
      return this.currentTime
    },
  },
  methods: {
    loadWaveform() {
      this.$nextTick(() => {
        this.player.load(this.musicFile)
      })
    },
    play: function () {
      console.log('再生')
      this.player.play(parseInt(this.currentTimeValue))
      this.nowPlaying = true
    },
    pause: function () {
      console.log('停止')
      this.player.pause()
      this.nowPlaying = false
    },
    getTime: function (aaa) {
      // 分計算
      console.log('getTime')
      const inputInt = parseInt(aaa)
      let m = Math.floor(inputInt / 60)
      if (m < 10) {
        m = String('0' + m)
      }
      // 秒数計算
      let s = Math.floor(inputInt % 60)
      if (s < 10) {
        s = String('0' + s)
      }
      const result = m + ':' + s
      return result
    },
    // 現在の再生時間取得
    getCurrentTime: function (aaa) {
      console.log(aaa)
      let currentTime = Math.floor(this.$refs.surf.waveSurfer.getCurrentTime())
      if (aaa === true) {
        console.log('true')

        this.currentTimeValue = 0
        this.currentTime = '00:00'
        this.currentTimeValue = 0
        return
      } else {
        this.currentTimeValue = currentTime
      }

      // console.log(this.$refs.surf.waveSurfer.getCurrentTime())
      this.currentTime = this.getTime(currentTime)
    },
    // 総再生時間
    getDuration: function () {
      const durationTime = Math.floor(this.$refs.surf.waveSurfer.getDuration())
      this.durationTime = this.getTime(durationTime)
      this.maxSongDuration = durationTime
    },
    seek() {
      this.currentTime = this.getTime(this.currentTimeValue)
    },
  },
})
</script>
<style scoped>
h1 {
  color: aliceblue;
}
p {
  color: aliceblue;
}

.wavesurferBtn {
  position: absolute;
  z-index: 2;
  top: 33%;
  right: 2%;
}
.wavesurfer {
  /* display: flex; */
  /* padding-bottom: -50px; */
  /* overflow: hidden !important; */
}
.wavesurfer_container {
  height: 76px; /* 適当な高さを指定 */
  overflow: hidden;
}
.v-card {
  background-color: transparent;
}
.v-btn {
  border-width: 4px;
}
.time {
  z-index: 5;
  position: relative;
  top: -30px;
}
.time-data {
  width: 55px;
  height: 15px;
  background: #565b6a;
  color: #fffb64;
}
.a {
  background: linear-gradient(to bottom, #00ff00, #ff0000);
}
</style>
