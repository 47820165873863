import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      isMobileView: false,
    }
  },
  methods: {
    handleResize() {
      window.addEventListener('resize', this.handleResize)
      // 画面サイズに応じて表示内容を切り替えるロジックを実装する
      if (window.innerWidth < 960) {
        this.isMobileView = true
      } else {
        this.isMobileView = false
      }
    },
  },
})
