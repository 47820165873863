
import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      devName: 'Test',
      logoData: require('@/assets/logo_typo.svg'),
      logoData2: require('@/assets/logo_icon.svg'),
    }
  },
  methods: {
    aaa() {
      alert('まだ実装出来てないよ！ここにはリンクを設定する予定だよ！ごめんね(＾ω＾)')
    },
  },
})
