import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37c5c48c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!

  return (_openBlock(), _createBlock(_component_v_toolbar, {
    class: "toolbar",
    flat: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, { class: "pa-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            class: "sp",
            justify: "start",
            align: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "4 pa-0",
                sm: "1 pa-0",
                md: "1 pa-0",
                lg: "1 pa-0"
              }),
              _createVNode(_component_v_col, {
                class: "d-flex justify-end",
                cols: "1 ",
                sm: "1 pa-0",
                md: "1 pa-0",
                lg: "1 pa-0",
                xl: "1 pa-0"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "logoData2",
                    src: _ctx.logoData2,
                    alt: "SVG Image"
                  }, null, 8, _hoisted_1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                class: "pa-0",
                justify: "start",
                cols: "1 pa-0",
                sm: "1 pa-0",
                md: "1 pa-0",
                lg: "2 pa-0",
                xl: "1 pa-0"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "logoData",
                    src: _ctx.logoData,
                    alt: "SVG Image"
                  }, null, 8, _hoisted_2)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_app_bar_nav_icon, { onClick: _ctx.aaa }, null, 8, ["onClick"])
    ]),
    _: 1
  }))
}