import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import TopPage from '../views/TopPage/TopPage.vue'
import LabContents from '../views/MainLab/MainLabLayout.vue'
import LabContentsList from '../views/LabList/LabContentsList.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'TopPage',
    component: TopPage,
  },
  // プレイヤー
  {
    path: '/LabContent/Lab/:name-projectnumber-:index',
    name: 'MainLab',
    component: LabContents,
    props: true,
  },
  // リスト
  {
    path: '/LabContent',
    name: 'ContentsList',
    component: LabContentsList,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

export default router
