
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HomeView',
  props: {
    developerName: String,
  },
  components: {},
  data() {
    return {
      devName: '',
    }
  },
  watch: {
    developerName: {
      // propsの値が変更されたらdevNameにセット
      handler(newVal) {
        this.devName = newVal
      },
      immediate: true, // 初期表示のために即時実行
    },
  },
})
