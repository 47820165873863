<template>
  <v-container class="pa-0 pt-4">
    <v-row>
      <v-col cols="10" class="pt-1">
        <v-row no-gutters class="pb-2 aaa">
          <div class="d-flex justify-end align-center pr-2">
            <v-icon color="#fff400" size="x-small">mdi-hexagon</v-icon>
          </div>
          <v-col>
            <h2 class="color">{{ projectData.projectName }}</h2>
          </v-col>
        </v-row>
        <div class="">
          <v-row class="pa-0 ma-0 mb-1" justify="start" align-items="center">
            <div class="py-0 px-2 mr-2 tube d-flex justify-center align-center">
              <span>TUBE LABEL</span>
            </div>
            <span class="d-flex justify-start align-center tube-data">{{ tubeNo }}</span>
          </v-row>
          <v-row class="pa-0 ma-0">
            <div class="py-0 pl-2 mr-2 tube d-flex justify-center align-center">
              <span class="tubes">UPDATE</span>
            </div>
            <span class="d-flex justify-center align-center tube-data">{{ updateAt }}</span>
          </v-row>
        </div>
      </v-col>
      <v-col cols="2" xl="2" lg="2" md="2 px-0" sm="2 px-0" class="d-flex justify-end align-center">
        <div>
          <v-img src="@/assets/l_play_btn.svg" v-if="!nowPlaying" @click="play" class="play_btn"></v-img>
          <v-img src="@/assets/stop_btn.svg" v-if="nowPlaying" @click="pause" class="play_btn"></v-img>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    activeTubeData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tubeNoData: '',
      updateAtData: '',
      nowPlaying: false,
    }
  },
  computed: {
    tubeNo() {
      return this.tubeNoData
    },
    updateAt() {
      return this.updateAtData
    },
  },
  watch: {
    activeTubeData(newValue) {
      console.log('activeTubeData')
      this.tubeNoData = newValue.tubeNo
      this.updateAtData = newValue.updateAt
    },
  },
  methods: {
    play() {
      console.log('再生')
      this.$parent.play()
      this.nowPlaying = true
    },
    pause() {
      console.log('停止')
      this.$parent.pause()
      this.nowPlaying = false
    },
    reset() {
      console.log('リセット')
      this.nowPlaying = false
    },
  },
  // created() {},
})
</script>
<style scoped>
span {
  color: aliceblue;
  font-size: small;
}
.tube {
  border: solid 1px;
  border-radius: 0px 30px 30px 0px;
  text-align: center;
  font-weight: bold;
  color: #777777;
  height: 15px;
}
.play_btn {
  width: 50px;
  height: 50px;
}
.aaa {
  /* background-color: aqua; */
}
.color {
  color: aliceblue;
}
.tube-data {
  height: 15px;
}
.tube-space {
  width: auto;
  background-color: aqua;
}
.tubes {
  width: 82px;
}
@media (max-width: 960px) {
  .aaa {
    position: relative;
    right: 20px;
    bottom: 10px;
    padding: 0 !important;
  }
  .play_btn {
    position: relative;
    right: 20px;
    bottom: 30px;
    transform: scale(0.9);
  }
}
</style>
