
import { defineComponent } from 'vue'

// Components
// import AudioVisualizerEQBar from "../components/AudioVisualizerEQBar.vue";
import MainLabContents from './Components/MainLabContents.vue'
import MainLabHeader from './Components/MainLabHeader.vue'
import SelectTube from './Components/SelectTube.vue'
// interface NewValueInterface {
//   tubeId: number
//   tubeNo: string
//   updateAt: string
//   musicFile: string
// }
export default defineComponent({
  name: 'HomeView',

  components: {
    MainLabHeader,
    MainLabContents,
    SelectTube,
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTube: {
        // developerName: '',
        // projectName: '',
        // projectImage: '',
        tubeId: 0,
        tubeNo: '',
        updateAt: '',
        musicFile: '',
        projectImage: '',
      },
      // Todo APIでデータ取得予定
      projectImageData: '',
    }
  },
  // computed: {
  //   projectImage() {
  //     return this.projectImageData
  //   },
  // },
  methods: {
    aaa() {
      ;(this.$refs.bbb as HTMLAudioElement).pause()
    },
    onSelectChanged(newValue: number) {
      this.activeTube = this.projectData.tubeData[newValue]
      this.projectImageData = this.projectData.tubeData[newValue].projectImage
      console.log('selectValue:', this.activeTube)
    },
    defaultTube() {
      console.log('aaa')
      this.activeTube = this.projectData.tubeData[0]
      console.log(this.activeTube)
      this.projectImageData = this.activeTube.projectImage
    },
    css() {
      console.log('start')
      let vh = window.innerHeight * 0.0088
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
  },
  mounted() {
    console.log('mounted')

    this.defaultTube()
    // this.css()
  },
})
