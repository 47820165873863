
import { defineComponent } from 'vue'
import screenLayoutMixin from '../../mixins/layouts'
export default defineComponent({
  mixins: [screenLayoutMixin],
  data() {
    return {
      isMobileView: false,
    }
  },
  methods: {},
  mounted() {
    this.handleResize()
  },
})
