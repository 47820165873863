<template>
  <v-sheet class="scrollable">
    <v-container class="pa-0" v-for="(tubeData, index) in projectData.tubeData" :key="index" fluid>
      <!-- Mobileの場合 -->
      <div v-if="isMobileView">
        <v-row class="ma-1 px-6 pt-2 d-flex justify-start align-center">
          <div class="tube-date">
            <v-icon
              :class="playSelect[index] ? 'play-selected mr-2' : 'play-selectable mr-2'"
              @click="selectTube(index)"
              size="x-large"
              v-model="playSelect">
              mdi-play
            </v-icon>

            <span class="font">{{ tubeData.updateAt }}</span>
          </div>
        </v-row>
        <v-row class="tube-label ma-0 pa-0 d-flex justify-start align-center">
          <div class="py-0 px-2 mr-2 tube d-flex justify-center align-center">
            <span>TUBE LABEL</span>
          </div>
          <span class="d-flex justify-start align-center tube-data">{{ tubeData.tubeNo }}</span>

          <div class="px-11">
            <v-divider color="#BDBDBD" class="border-opacity-50" />
          </div>
        </v-row>
      </div>
      <!-- PCの場合 -->
      <div v-else>
        <v-row class="ma-1 px-12 py-2 d-flex justify-start align-center">
          <div class="tube-date">
            <v-icon
              :class="playSelect[index] ? 'play-selected mr-2' : 'play-selectable mr-2'"
              @click="selectTube(index)"
              size="x-large"
              v-model="playSelect">
              mdi-play
            </v-icon>

            <span class="font">{{ tubeData.updateAt }}</span>
          </div>
          <div class="py-0 px-2 mr-2 tube d-flex justify-center align-center">
            <span>TUBE LABEL</span>
          </div>
          <span class="d-flex justify-start align-center tube-data">{{ tubeData.tubeNo }}</span>
        </v-row>
        <div class="px-11">
          <v-divider color="#BDBDBD" class="border-opacity-50" />
        </div>
      </div>
    </v-container>
  </v-sheet>
</template>
<script>
import { defineComponent } from 'vue'
import screenLayoutMixin from '../../../mixins/layouts'

export default defineComponent({
  mixins: [screenLayoutMixin],

  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectTubeData: {
        tubeId: 0,
        tubeNo: '',
        updatedAt: '',
        musicFile: '',
      },
      playSelect: [],
    }
  },
  components: {},
  methods: {
    selectTube(index) {
      console.log('selected')
      this.playSelect = []
      this.playSelect[index] = true
      this.$emit('selectChanged', index)
    },
    defaultSelectData() {
      console.log('defaultSelectData')
      this.selectTubeData = this.projectData.tubeData[0]
      this.playSelect = this.projectData.tubeData.map(() => false)
    },
  },
  mounted() {
    this.defaultSelectData()
    this.handleResize()
  },
})
</script>
<style scoped>
span {
  color: #515766;
  font-size: small;
}
.font {
  font-size: small;
}
.noPadding {
  padding: 0;
  width: 100%;
  white-space: nowrap;
}

.noRadius {
  border-radius: 0;
}

.tube {
  border: solid 1px;
  border-radius: 0px 30px 30px 0px;
  text-align: center;
  color: #515766;
  height: 17px;
}
.tube span {
  position: relative;
  bottom: -1px;
}
.tube-label {
  position: relative;
  left: 68px;
  bottom: 10px;
}

.tube-data {
  height: 17px;
}
.width {
  width: 50%;
}
.play-selected {
  color: #000;
}

.play-selectable {
  color: #bbbbbb;
}
.tube-date {
  width: 165px;
}

@media (max-width: 960px) {
  .scrollable {
    overflow-x: none;
    overflow-y: none; /* スクロールバーを表示 */
  }
  .font {
    font-size: small;
  }
}
</style>
