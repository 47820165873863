
import HeaderMenu from '../Layout/HeaderMenu.vue'
import FooterComponents from '../Layout/FooterComponents.vue'
import MainLab from './MainLab.vue'
import { defineComponent } from 'vue'
import screenLayoutMixin from '../../mixins/layouts'

export default defineComponent({
  name: 'App',
  mixins: [screenLayoutMixin],
  components: {
    HeaderMenu,
    FooterComponents,
    MainLab,
  },
  props: {
    index: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      overlay: false,
      // Todo APIでデータ取得予定
      projectData: [
        {
          developerName: 'Kento',
          projectName: 'Be Awake',
          updateAt: '2023.5.16 10:02:10',
          tubeData: [
            {
              tubeId: 1,
              tubeNo: '1_Idea',
              updateAt: '2023.3.26 20:43:04',
              musicFile: require('@/assets/Be_Awake/1_Idea.wav'),
              projectImage: require('@/assets/Be_Awake/1_idea.png'),
            },
            {
              tubeId: 2,
              tubeNo: '2_Onechorus',
              updateAt: '2023.4.6 23:23:29',
              musicFile: require('@/assets/Be_Awake/2_Onechorus.wav'),
              projectImage: require('@/assets/Be_Awake/2_onechorus.png'),
            },
            {
              tubeId: 3,
              tubeNo: '3_Demo',
              updateAt: '2023.4.10 10:53:40',
              musicFile: require('@/assets/Be_Awake/3_Demo.wav'),
              projectImage: require('@/assets/Be_Awake/3_demo.png'),
            },
            {
              tubeId: 4,
              tubeNo: '4_RoughMIX',
              updateAt: '2023.5.1 4:03:35',
              musicFile: require('@/assets/Be_Awake/4_RoughMIX.wav'),
              projectImage: require('@/assets/Be_Awake/3_demo.png'),
            },
            {
              tubeId: 5,
              tubeNo: '5_Master',
              updateAt: '2023.5.16 10:02:10',
              musicFile: require('@/assets/Be_Awake/5_Master.wav'),
              projectImage: require('@/assets/Be_Awake/5_fin.png'),
            },
          ],
        },
        {
          developerName: 'Takumi',
          projectName: 'Grow',
          updateAt: '2023.6.5 03:45:33',
          tubeData: [
            {
              tubeId: 1,
              tubeNo: '1_Onechorus',
              updateAt: '2023.3.26 20:43:04',
              musicFile: require('@/assets/Grow/1_Onechorus.wav'),
              projectImage: require('@/assets/Grow/1_Onechorus.png'),
            },
            {
              tubeId: 2,
              tubeNo: '2_GtArrange',
              updateAt: '2023.6.5 03:45:33',
              musicFile: require('@/assets/Grow/2_GtArrange.wav'),
              projectImage: require('@/assets/Grow/2_GtArrange.png'),
            },
          ],
        },
      ],
      projectImageData: '',
    }
  },
  computed: {
    selectedProjectData() {
      return this.projectData[Number(this.index)]
    },
  },
  watch: {
    overlay() {
      console.log('bbb')
      console.log(this.overlay)
      setTimeout(() => {
        this.overlay = false
      }, 3000)
    },
  },
  mounted() {
    this.handleResize()
  },
  methods: {},
})
