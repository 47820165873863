
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LabContentsListLine',
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
})
