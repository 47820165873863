
import ProjectListLine from './ProjectListLine.vue'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LabContentsList',
  components: {
    ProjectListLine,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
})
